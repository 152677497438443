import React, {useEffect, useState} from "react";
import {
    ComposedChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label,
    Scatter
} from 'recharts';


function CoachDashboard() {
    const [athleteData, setAthleteData] = useState([]);
    const [searchParams, setSearchParams] = useState({
        athletes: [],
        exercises: [],
        questions: [],
        startDate: undefined,
        endDate: undefined,
    });
    const [sortBy, setSortBy] = useState("date");

    const [availableAthletes, setAvailableAthletes] = useState([]);
    const availableExercises = [
       /* "Barbell Backsquat",
        "Barbell Backsquat Super Set",
        "Lunges",
        "Romanian Deadlift",
        "Copenhagens (short lever)",
        "Step Downs",
        "Pogo Hops",
        "Banded Hip Adduction",
        "Spanish Squat",
        "Tibraises",
        "Langhantel Bankdrücken",
        "Kurzhantel Bankdrücken",
        "Bankdrücken an Maschine",
        "Langhantel Rudern",
        "Helms Rows",
        "Rudern an Maschine",
        "Langhantel Overheadpress stehend",
        "Langhantel Overheadpress sitzend",
        "Kurzhantel Overheadpress stehend",
        "Kurzhantel Overheadpress sitzend",
        "Overheadpress an Maschine",
        "Lat Pulldown",
        "Klimmzüge",
        "Pallof Press"*/
    ];
    const availableQuestions = [
        {questionId: 0, name: "Bereitschaft"},
        {questionId: 1, name: "Bereitschaft Körper/Mental"},
        {questionId: 2, name: "Belastung"},
        {questionId: 3, name: "Schlafqualität"},
        {questionId: 4, name: "Schlafdauer"},
        {questionId: 5, name: "Körperliche Verfassung"},
        {questionId: 6, name: "Mentale Verfassung"},
        {questionId: 7, name: "Stress"},
    ];

    const availableMetrics = [
      // {id: "difficulty", name: "Schwierigkeit"},
      //  {id: "tonnage", name: "Tonnage"},
      // {id: "avgWeight", name: "Avg. Gewicht"},
       // {id: "avgReps", name: "Avg. Reps"},
        {id: "questionValue", name: "Wert"}
    ];
    const [selectedMetrics, setSelectedMetrics] = useState([  "questionValue"]);

    useEffect(() => {
        loadAvailableAthletes();
    }, []);

    const search = async () => {
        try {
            let queryParams = "";
            for (const athlete of searchParams.athletes) {
                queryParams += "athletes[]=" + athlete + "&";
            }
            for (const exercise of searchParams.exercises) {
                queryParams += "exercises[]=" + exercise + "&";
            }
            for (const question of searchParams.questions) {
                queryParams += "questions[]=" + question + "&";
            }
            if (searchParams.startDate) {
                queryParams += "startDate=" + searchParams.startDate + "&";
            }
            if (searchParams.endDate) {
                queryParams += "endDate=" + searchParams.endDate + "&";
            }
            const url = `/api/search?${queryParams}`;
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            let data = await response.json();
            data = [...data.exercises.map(exercise => {
                let time = new Date(exercise.date);
                time.setHours(0,0,0,0);
                return {
                    ...exercise,
                    type: "exercise",
                    time: time.getTime(),
                    avgReps: exercise.actualReps && exercise.actualReps.length === Math.round(exercise.sets) ? (exercise.actualReps.reduce((sum, rep) => sum + rep, 0) / Math.round(exercise.sets)).toFixed(2) : undefined,
                    tonnage: exercise.actualWeights && exercise.actualWeights.length === Math.round(exercise.sets) ? exercise.actualWeights.reduce((sum, weight, index) => sum + weight * exercise.actualReps[index], 0) : undefined,
                    avgWeight: exercise.actualWeights && exercise.actualWeights.length === Math.round(exercise.sets) ? (exercise.actualWeights.reduce((sum, weight, index) => sum + weight * exercise.actualReps[index], 0) / exercise.actualReps.reduce((sum, reps) => sum + reps)).toFixed(2) : undefined,
                }
            }),
                ...data.questions.map(question => {
                    let time = new Date(question.date);
                    time.setHours(0,0,0,0);
                    return {
                        ...question,
                        type: "question",
                        name: availableQuestions.find(availableQuestion => availableQuestion.questionId === question.questionId).name,
                        time: time.getTime(),
                    }
                })];
            data = data.sort((a, b) => {
                if (a[sortBy] < b[sortBy]) return -1;
                if (a[sortBy] > b[sortBy]) return 1;
                return 0;
            });
            setAthleteData(data);
        } catch (error) {
            console.error('Error fetching questionnaire:', error);
        }
    };

    const loadAvailableAthletes = async () => {
        try {
            const response = await fetch(`/api/users`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setAvailableAthletes(data);
        } catch (error) {
            console.error('Error fetching questionnaire:', error);
        }
    };

    const handleInputChange = (e) => {
        let {name, value, checked} = e.target;
        if (name === "questions") {
            value = parseInt(value);
        }
        if (name === "startDate" || name === "endDate") {
            setSearchParams({...searchParams, [name]: value});
        } else {
            setSearchParams({
                ...searchParams,
                [name]: checked
                    ? [...searchParams[name], value]
                    : searchParams[name].filter((item) => item !== value),
            });
        }
    };

    const handleLabelClick = (type, value) => {
        const isChecked = searchParams[type].includes(value);
        setSearchParams({
            ...searchParams,
            [type]: isChecked
                ? searchParams[type].filter((item) => item !== value)
                : [...searchParams[type], value],
        });
    };


    const handleSelectAll = (type) => {
        setSearchParams({...searchParams, [type]: [...availableItems(type)]});
    };

    const handleSelectNone = (type) => {
        setSearchParams({...searchParams, [type]: []});
    };

    const handleToggleDate = (name) => {
        setSearchParams({
            ...searchParams,
            [name]: searchParams[name] ? undefined : new Date().toISOString().split('T')[0]
        });
    };

    const availableItems = (type) => {
        switch (type) {
            case "athletes":
                return availableAthletes.map(athlete => athlete._id);
            case "exercises":
                return availableExercises;
            case "questions":
                return availableQuestions.map((question) => question.questionId);
            default:
                return [];
        }
    };

    useEffect(() => {
        // Create a copy of the athleteData array
        const sortedData = [...athleteData];

        // Sort the copy
        sortedData.sort((a, b) => {
            if (a[sortBy] < b[sortBy]) return -1;
            if (a[sortBy] > b[sortBy]) return 1;
            return 0;
        });

        // Update the state with the sorted copy
        setAthleteData(sortedData);
    }, [sortBy]);

    function handleSortByChange(e) {
        const {name} = e.target;
        setSortBy(name);
    }

    const handleMetricChange = (metric) => {
        setSelectedMetrics(prevMetrics => {
            if (prevMetrics.includes(metric.id)) {
                return prevMetrics.filter(item => item !== metric.id);
            } else {
                return [...prevMetrics, metric.id];
            }
        });
    };


    function displayChart() {

        let chartData = {};


        for (const selectedMetricId of selectedMetrics) {
            const selectedMetric = availableMetrics.find(item => item.id === selectedMetricId);

            for (const entry of athleteData) {
                let key = undefined;
                let label = undefined;
                let value = undefined;


                if (entry.type === "exercise") {
                    const exercise = entry;
                    if (selectedMetric.id === "difficulty" && exercise.actualReps && exercise.actualReps.length === Math.round(exercise.sets) && exercise.difficulty) {
                        key = "U_" + exercise.userId.name + "-E_" + availableExercises.indexOf(exercise.name) + "-M_" + selectedMetric.id;
                        label = exercise.userId.name + " - " + exercise.name + " - " + selectedMetric.name;
                        value = exercise.difficulty;
                    } else if (selectedMetric.id === "tonnage" && exercise.tonnage) {
                        key = "U_" + exercise.userId.name + "-E_" + availableExercises.indexOf(exercise.name) + "-M_" + selectedMetric.id;
                        label = exercise.userId.name + " - " + exercise.name + " - " + selectedMetric.name;
                        value = exercise.tonnage;
                    } else if (selectedMetric.id === "avgWeight" && exercise.avgWeight) {
                        key = "U_" + exercise.userId.name + "-E_" + availableExercises.indexOf(exercise.name) + "-M_" + selectedMetric.id;
                        label = exercise.userId.name + " - " + exercise.name + " - " + selectedMetric.name;
                        value = exercise.avgWeight;
                    } else if (selectedMetric.id === "avgReps" && exercise.avgReps) {
                        key = "U_" + exercise.userId.name + "-E_" + availableExercises.indexOf(exercise.name) + "-M_" + selectedMetric.id;
                        label = exercise.userId.name + " - " + exercise.name + " - " + selectedMetric.name;
                        value = exercise.avgReps;
                    }
                } else if (entry.type === "question") {
                    const question = entry;
                    if (selectedMetric.id === "questionValue" && question.value !== -6) {
                        key = "U_" + question.userName + "-Q_" + question.questionId;
                        label = question.userName + " - " + availableQuestions.find(availableQuestion => availableQuestion.questionId === question.questionId).name
                        value = question.value;
                    }
                }

                if (key && label && value) {
                    if (!chartData[key]) {
                        chartData[key] = {
                            data: [],
                            label: label
                        }
                    }
                    chartData[key].data.push({...entry, [key]: value});
                }

            }


        }

        const CustomTooltip = ({ active, label, payload }) => {
            if (active && payload && payload.length) {
                return (
                    <div className="">
                        <p className={"font-bold"}>{(new Date(label)).toLocaleDateString("de-DE")}</p>
                        {payload.map((entry, index) => <div key={index}>
                            <span style={{ color: entry.color }}>{entry.name}</span>
                            <span className={"ml-2"}>{entry.value}</span>
                        </div>)}
                    </div>
                );
            }

            return null;
        };

        return <div className="bg-accent border rounded-2xl p-4 mt-4 aspect-video w-full">
            <h1 className="text-2xl text-secondary font-bold mb-3">Chart</h1>
            <ResponsiveContainer width="100%" height="100%" className={"min-h-screen md:min-h-0"}>
                <ComposedChart
                    margin={{top: 5, right: 30, left: 20, bottom: 5}}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="time" type={"number"} domain={["auto", "auto"]} scale={"time"}
                           tickFormatter={time => (new Date(time)).toLocaleDateString('de-DE', {
                               day: '2-digit',
                               month: '2-digit',
                               year: 'numeric',
                           })}/>
                    <YAxis/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Legend/>
                    {Object.keys(chartData).map(key =>
                        <Line
                            key={key}
                            dataKey={key}
                            data={chartData[key].data}
                            type="monotone"
                            stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
                            name={chartData[key].label}
                        />
                    )}
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    }

    const roundWeight = (weight) => {
        return Math.round(weight / 2.5) * 2.5;
    }

    function displayTable() {
        return athleteData.map((entry, index) => <div key={index}
                                                      className={"bg-accent rounded-xl p-2 m-1 flex flex-wrap"}>
            <div className={"flex flex-col mr-5 border border-secondary border-solid mx-2 p-2 rounded-2xl"}>
                <p className={"text-xl font-bold text-secondary"}>{entry.name}</p>
                <p className={"font-bold"}>{entry.type === "question" ? entry.userName : entry.userId.name}</p>
                <p className={"text-lg text-secondary"}>{(new Date(entry.date)).toLocaleDateString('de-DE', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit"
                })} Uhr</p>
                <p>{entry.workout}</p>
            </div>
            {entry.type === "exercise" && <>
                {entry.hasWeights && <>
                    <div
                        className={"flex flex-col border border-primary border-solid m-2 p-2 rounded-2xl items-center"}>
                        <h2 className={"text-primary text-md"}>Gewicht (Soll)</h2>
                        {(Array(Math.round(entry.sets)).fill(roundWeight(entry.goalWeight))).map((weight, index) => <p
                            key={index}>{weight} kg</p>)}
                    </div>
                    <div
                        className={"flex flex-col border border-secondary border-solid m-2 p-2 rounded-2xl items-center"}>
                        <h2 className={"text-secondary text-md"}>Gewicht (Ist)</h2>
                        {entry.actualWeights.map((weight, index) => <p key={index}>{weight} kg</p>)}
                    </div>
                </>}

                <div className={"flex flex-col border border-primary border-solid m-2 p-2 rounded-2xl items-center"}>
                    <h2 className={"text-primary text-md"}>Reps (Soll)</h2>
                    {(Array(Math.round(entry.sets)).fill(entry.goalReps)).map((reps, index) => <p
                        key={index}>{reps}</p>)}
                </div>

                <div className={"flex flex-col border border-secondary border-solid m-2 p-2 rounded-2xl items-center"}>
                    <h2 className={"text-secondary text-md"}>Reps (Ist)</h2>
                    {entry.actualReps.map((reps, index) => <p key={index}>{reps}</p>)}
                </div>
                <div className={"flex flex-col border border-secondary border-solid m-2 p-2 rounded-2xl items-center"}>
                    <h2 className={"text-secondary text-md"}>Schwierigkeit</h2>
                    <p>{entry.difficulty}</p>
                </div>
                {entry.tonnage && <div
                    className={"flex flex-col border border-secondary border-solid m-2 p-2 rounded-2xl items-center"}>
                    <h2 className={"text-secondary text-md"}>Tonnage</h2>
                    <p>{entry.tonnage} kg</p>
                </div>}
                {entry.avgWeight && <div
                    className={"flex flex-col border border-secondary border-solid m-2 p-2 rounded-2xl items-center"}>
                    <h2 className={"text-secondary text-md"}>Avg. Gewicht</h2>
                    <p>{entry.avgWeight} kg</p>
                </div>}
                {entry.avgReps && <div
                    className={"flex flex-col border border-secondary border-solid m-2 p-2 rounded-2xl items-center"}>
                    <h2 className={"text-secondary text-md"}>Avg. Reps</h2>
                    <p>{entry.avgReps}</p>
                </div>}
                {entry.deload && <div
                    className={"flex flex-col border border-secondary border-solid m-2 p-2 rounded-2xl items-center"}>
                    <h2 className={"text-secondary text-md"}>Deload Phase</h2>
                </div>}
            </>}
            {entry.type === "question" &&
                <div className={"flex flex-col border border-secondary border-solid m-2 p-2 rounded-2xl items-center"}>
                    <h2 className={"text-secondary text-md"}>Wert</h2>
                    <p>{entry.value}</p>
                </div>}
        </div>);


    }


    return (
        <div className="m-4">
            <div className="bg-accent border rounded-2xl p-4">
                <h1 className="text-2xl text-secondary font-bold mb-3">Suche</h1>
                <form>
                    <div className="flex justify-between flex-wrap">
                        <div className={"m-2"}>
                            <h1 className="text-xl text-secondary font-bold">Athleten</h1>
                            {availableAthletes.map((athlete) => (
                                <div key={athlete._id}>
                                    <input
                                        type="checkbox"
                                        name="athletes"
                                        value={athlete._id}
                                        onChange={handleInputChange}
                                        checked={searchParams.athletes.includes(athlete._id)}
                                    />
                                    <label className={"ml-1"}
                                           onClick={() => handleLabelClick('athletes', athlete._id)}>{athlete.name}</label>
                                </div>
                            ))}
                            <input
                                type="button"
                                value="Alle auswählen"
                                onClick={() => handleSelectAll("athletes")}
                                className="bg-gray-200 rounded-xl mt-1 mr-1 p-1"
                            />
                            <input
                                type="button"
                                value="Auswahl löschen"
                                onClick={() => handleSelectNone("athletes")}
                                className="bg-gray-200 rounded-xl mt-1 mr-1 p-1"
                            />
                        </div>
                        <div className={"m-2"}>
                            <h1 className="text-xl text-secondary font-bold">Übungen</h1>
                            {availableExercises.map((exercise, index) => (
                                <div key={index}>
                                    <input
                                        type="checkbox"
                                        name="exercises"
                                        value={exercise}
                                        onChange={handleInputChange}
                                        checked={searchParams.exercises.includes(exercise)}
                                    />
                                    <label className={"ml-1"}
                                           onClick={() => handleLabelClick('exercises', exercise)}>{exercise}</label>
                                </div>
                            ))}
                            <input
                                type="button"
                                value="Alle auswählen"
                                onClick={() => handleSelectAll("exercises")}
                                className="bg-gray-200 rounded-xl mt-1 mr-1 p-1"
                            />
                            <input
                                type="button"
                                value="Auswahl löschen"
                                onClick={() => handleSelectNone("exercises")}
                                className="bg-gray-200 rounded-xl mt-1 mr-1 p-1"
                            />
                        </div>
                        <div className={"m-2"}>
                            <h1 className="text-xl text-secondary font-bold">Fragen</h1>
                            {availableQuestions.map((question) => (
                                <div key={question.questionId}>
                                    <input
                                        type="checkbox"
                                        name="questions"
                                        value={question.questionId}
                                        onChange={handleInputChange}
                                        checked={searchParams.questions.includes(question.questionId)}
                                    />
                                    <label className={"ml-1"}
                                           onClick={() => handleLabelClick('questions', question.questionId)}>{question.name}</label>
                                </div>
                            ))}
                            <input
                                type="button"
                                value="Alle auswählen"
                                onClick={() => handleSelectAll("questions")}
                                className="bg-gray-200 rounded-xl mt-1 mr-1 p-1"
                            />
                            <input
                                type="button"
                                value="Auswahl löschen"
                                onClick={() => handleSelectNone("questions")}
                                className="bg-gray-200 rounded-xl mt-1 mr-1 p-1"
                            />
                        </div>
                        <div className={"m-2"}>
                            <h1 className="text-xl text-secondary font-bold">Startdatum (optional)</h1>
                            {searchParams.startDate === undefined ? (
                                <input
                                    type="button"
                                    value="+"
                                    onClick={() => handleToggleDate("startDate")}
                                    className="bg-gray-200 rounded-xl mt-1 mr-1 p-1"
                                />
                            ) : (
                                <div>
                                    <input
                                        type="date"
                                        name="startDate"
                                        value={searchParams.startDate}
                                        onChange={handleInputChange}
                                    />
                                    <input
                                        type="button"
                                        value="x"
                                        onClick={() => handleToggleDate("startDate")}
                                        className="bg-gray-200 rounded-xl mt-1 p-1"
                                    />
                                </div>
                            )}
                            <h1 className="text-xl text-secondary font-bold">Enddatum (optional)</h1>
                            {searchParams.endDate === undefined ? (
                                <input
                                    type="button"
                                    value="+"
                                    onClick={() => handleToggleDate("endDate")}
                                    className="bg-gray-200 rounded-xl mt-1 mr-1 p-1"
                                />
                            ) : (
                                <div>
                                    <input
                                        type="date"
                                        name="endDate"
                                        value={searchParams.endDate}
                                        onChange={handleInputChange}
                                    />
                                    <input
                                        type="button"
                                        value="x"
                                        onClick={() => handleToggleDate("endDate")}
                                        className="bg-gray-200 rounded-xl mt-1 mr-1 p-1"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <input
                        className="bg-secondary rounded-2xl mt-3 p-3 min-w-full text-accent"
                        type="button"
                        value="Suchen"
                        onClick={search}
                    />
                </form>
            </div>
            {athleteData.length > 0 && <div>
                <div className="bg-accent border rounded-2xl p-4 mt-4">
                    <h1 className="text-2xl text-secondary font-bold mb-3">Metriken</h1>
                    <form className={"flex flex-wrap"}>
                        {availableMetrics.map(metric => (
                            <label key={metric.id} className="mr-3">
                                <input
                                    type="checkbox"
                                    value={metric.id}
                                    checked={selectedMetrics.includes(metric.id)}
                                    onChange={() => handleMetricChange(metric)}
                                    className="mr-1"
                                />
                                {metric.name}
                            </label>
                        ))}
                    </form>
                </div>
                {displayChart()}

                <div className="bg-accent border rounded-2xl p-4 mt-4">
                    <h1 className="text-2xl text-secondary font-bold mb-3">Sortierung</h1>
                    <form className={"flex flex-wrap"}>
                        <input
                            className={sortBy === "name" ? "mr-3 border border-primary p-2 rounded-2xl bg-primary text-accent text-xl font-bold" : "mr-3 border border-primary p-2 rounded-2xl text-xl text-secondary font-bold"}
                            type={"button"} value={"Übungs-/Fragenname"} name={"name"} onClick={handleSortByChange}/>
                        <input
                            className={sortBy === "userId" ? "mr-3 border border-primary p-2 rounded-2xl bg-primary text-accent font-bold" : "mr-3 border border-primary p-2 rounded-2xl font-bold text-dark"}
                            type={"button"} value={"Athlet"} name={"userId"} onClick={handleSortByChange}/>
                        <input
                            className={sortBy === "date" ? "mr-3 border border-primary p-2 rounded-2xl bg-primary text-accent text-lg" : "mr-3 border border-primary p-2 rounded-2xl text-lg text-secondary"}
                            type={"button"} value={"Datum"} name={"date"} onClick={handleSortByChange}/>
                        <input
                            className={sortBy === "workout" ? "mr-3 border border-primary p-2 rounded-2xl bg-primary text-accent" : "mr-3 border border-primary p-2 rounded-2xl"}
                            type={"button"} value={"Session"} name={"workout"} onClick={handleSortByChange}/>

                    </form>
                </div>

                {displayTable()}
            </div>}
        </div>
    );
}

export default CoachDashboard;
