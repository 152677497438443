// WorkoutQuestionnaire.js
import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Header from "../../Util/Header";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";

const WorkoutQuestionnairePage = () => {
    const [questionnaire, setQuestionnaire] = useState(null);
    const [errors, setErrors] = useState("");
    const [loading, setLoading] = useState(true);
    const [readOnly, setReadOnly] = useState(false);
    const {id} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchQuestionnaire = async () => {
            try {
                const response = await fetch(`/api/workoutquestionnaires/${id}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setQuestionnaire(data);
                setLoading(false);
                if (!data.questions.some(question => question.value === -6)) {
                    setReadOnly(true);
                }
            } catch (error) {
                console.error('Error fetching questionnaire:', error);
                setLoading(false);
            }
        };
        fetchQuestionnaire();
    }, [id]);

    const handleSliderChange = (index, value) => {
        const updatedQuestions = [...questionnaire.questions];
        updatedQuestions[index].value = value;
        setQuestionnaire(prevQuestionnaire => ({
            ...prevQuestionnaire,
            questions: updatedQuestions
        }));
    };

    async function handleFinishQuestionnaire() {
        try {
            if (questionnaire.questions.some(question => question.value === -6)) {
                setErrors("Bitte alle Fragen beantworten.");
                return;
            }
            setErrors("");

            const response = await fetch(`/api/workoutquestionnaires/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(questionnaire)
            });
            if (!response.ok) {
                throw new Error('Failed to finish exercise');
            }
            // Handle success, maybe redirect or show a message
            navigate(-1);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <Header primaryColor="primary" secondaryColor="secondary"/>
            <div className={"bg-dark rounded-md p-2 m-2 text-accent max-w-full py-3"} onClick={e => navigate(-1)}><span
                className={"text-accent mr-2"}><FontAwesomeIcon
                icon={faChevronLeft}/> Zurück zur Session-Übersicht</span></div>

            <div className="m-4">

                {loading ? (
                    <p>Loading questionnaire...</p>
                ) : questionnaire ? (
                    <div>
                        <h1 className="text-2xl font-bold mb-4 text-secondary">{questionnaire.name}</h1>
                        <p>Datum: {(new Date(questionnaire.date)).toLocaleDateString('de-DE', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        })}</p>
                        <div>
                            <h2 className="text-lg font-bold my-2 text-secondary">Fragen</h2>
                            {questionnaire.questions.map((question, index) => (
                                <div key={index} className="mb-4 border rounded-2xl bg-accent p-3">
                                    <h3 className={"text-md font-bold text-secondary"}>{question.question}</h3>
                                    <p>Von {question.rangeMin} ({question.valueTexts[question.rangeMin]})
                                        bis {question.rangeMax} ({question.valueTexts[question.rangeMax]})</p>
                                    <style>
                                        {`
                                          .slider::-webkit-slider-thumb {
                                            -webkit-appearance: none;
                                            appearance: none;
                                            width: 25px;
                                            height: 25px;
                                            border-radius: 50%; 
                                            background: #0B60B0;
                                            cursor: pointer;
                                          }
                                
                                          .slider::-moz-range-thumb {
                                            width: 25px;
                                            height: 25px;
                                            border-radius: 50%;
                                            background: #0B60B0;
                                            cursor: pointer;
                                          }
                                        `}
                                    </style>
                                    <input
                                        type="range"
                                        min={question.rangeMin}
                                        max={question.rangeMax}
                                        value={question.value}
                                        step={question.step}
                                        disabled={readOnly}
                                        onChange={(e) => handleSliderChange(index, parseFloat(e.target.value))}
                                        className="w-full my-3 slider"
                                        style={{
                                            WebkitAppearance: 'none',
                                            width: '100%',
                                            height: '15px',
                                            borderRadius: '5px',
                                            background: '#d3d3d3',
                                            outline: 'none',
                                            opacity: 0.7,
                                            transition: 'opacity .2s',
                                        }}
                                    />
                                    {question.value === -6 ? <p>Bitte auswählen</p> :
                                        <p>Deine Auswahl: {question.value} {question.valueTexts[question.value]}</p>}
                                </div>
                            ))}
                        </div>
                        {/* Add more exercise details as needed */}
                        <p className={"text-red-500"}>{errors}</p>
                        {!readOnly && <button className={"bg-primary border rounded-md p-5"}
                                              onClick={handleFinishQuestionnaire}>Questionnaire abschließen</button>}
                    </div>
                ) : (
                    <p>Questionnaire nicht gefunden</p>
                )}
            </div>
        </div>
    );
};

export default WorkoutQuestionnairePage;
