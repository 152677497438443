import React, {useState, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Header from '../../Util/Header';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";

const WorkoutexercisePage = () => {
    const [exercise, setExercise] = useState(null);
    const {id} = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState("");
    const [readOnly, setReadOnly] = useState(false);

    useEffect(() => {
        const fetchWorkoutexercise = async () => {
            try {
                const response = await fetch(`/api/workoutexercises/${id}`, {
                    credentials: 'include'
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch workout');
                }
                const data = await response.json();
                data.sets = Math.round(data.sets);
                //if exercise is loaded the first time
                if (!data.actualReps || (data.actualReps && data.actualReps.length === 0)) {
                    if (data.hasWeights) {
                        data.actualWeights = new Array(data.sets).fill(roundWeight(data.goalWeight));
                        data.actualReps = new Array(data.sets).fill("");
                    } else {
                        data.actualReps = new Array(data.sets).fill(data.goalReps);
                    }

                    data.difficulty = -1;

                } else {
                    setReadOnly(true);
                }
                if (!data.hasWeights){
                    data.actualWeights = new Array(data.sets).fill("Bodyweight");
                }
                setExercise(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchWorkoutexercise();
    }, [id]);

    const handleFinishExercise = async () => {
        try {
            if (exercise.actualReps.includes("") || exercise.actualWeights.includes("") || exercise.actualReps.includes(null) || exercise.actualWeights.includes(null)) {
                setErrors("Bitte Übung fertig ausführen und alle Felder ausfüllen.");
                return;
            }
            if (exercise.difficulty === -1){
                setErrors("Bitte Schwierigkeit angeben.")
                return;
            }
            setErrors("");

            const response = await fetch(`/api/workoutexercises/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(exercise)
            });
            if (!response.ok) {
                throw new Error('Failed to finish exercise');
            }
            // Handle success, maybe redirect or show a message
            navigate(-1);
        } catch (error) {
            console.error(error);
        }
    };

    const handleSliderChange = (e) => {
        setExercise({
                ...exercise,
                difficulty: parseInt(e.target.value)
            }
        );
    };

    const roundWeight = (weight) => {
        return Math.round(weight / 2.5) * 2.5;
    }

    const calculateNewWeights = (index, reps, actualReps) => {
        const newWeights = [...exercise.actualWeights];
        if (index + 1 < exercise.actualWeights.length && actualReps.filter((value, i) => (i >= index && value === "")).length === actualReps.length - index - 1) {
            let newWeight = exercise.actualWeights[index];
            if (exercise.goalReps - reps > 2) {
                newWeight = roundWeight(newWeight - 2.5);
            } else if (exercise.goalReps - reps < -2) {
                newWeight = roundWeight(newWeight + 2.5);
            }

            for (let i = index + 1; i < newWeights.length; i++) {
                    newWeights[i] = newWeight;
            }


        }

        setExercise({
            ...exercise,
            actualReps: actualReps,
            actualWeights: newWeights
        })

    }

    const getDifficultyLabel = (difficulty) => {
        switch (difficulty) {
            case 1:
                return "Sehr leicht";
            case 2:
                return "Leicht";
            case 3:
                return "Genau richtig";
            case 4:
                return "Schwer";
            case 5:
                return "Zu Schwer";
            default :
                return "";
        }
    }

    return (
        <div className="min-h-screen bg-gray-100">
            <Header primaryColor="primary" secondaryColor="secondary"/>
            <div className={"bg-dark rounded-md p-2 m-2 text-accent max-w-full py-3"} onClick={e =>  navigate(-1)}><span className={"text-accent mr-2"}><FontAwesomeIcon icon={faChevronLeft} /> Zurück zur Session-Übersicht</span></div>
            <div className="m-4">
                {exercise ? (
                    <div>
                        <h1 className="text-2xl font-bold mb-4 text-secondary">{exercise.name}</h1>
                        <p>Datum: {(new Date(exercise.date)).toLocaleDateString('de-DE', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                        })}</p>
                        {/*    <p>Name: {exercise.name}</p>
                        <p>Has Weights: {exercise.hasWeights ? 'Yes' : 'No'}</p>
                        <p>Sets: {exercise.sets}</p>
                        <p>Goal Weight: {exercise.hasWeights ? exercise.goalWeight : "Bodyweight"}</p>
                        <p>Goal Reps: {exercise.goalReps}</p> */}
                        {exercise.hasWeights && !readOnly && <div>
                            <h2 className="text-xl font-bold mt-4 text-secondary">Warmup vor der Übung</h2>
                            <p>Set 1: Gewicht: {roundWeight(exercise.goalWeight*0.3)} kg, Reps: {exercise.goalReps}</p>
                            <p>Set 2: Gewicht: {roundWeight(exercise.goalWeight*0.6)} kg, Reps: {exercise.goalReps}</p>
                            <p>Set 3: Gewicht: {roundWeight(exercise.goalWeight*0.8)} kg, Reps: 3-5</p>

                        </div>}

                        {/* Actual Weights */}
                        <h2 className="text-xl font-bold mt-4 text-secondary">Übung</h2>
                        {exercise.actualWeights.map((weight, index) => (
                            <div className={"bg-secondary p-2 w-fit rounded-md my-2 w-full"} key={index}>
                                {exercise.hasWeights &&<input
                                    className={"w-20 text-center rounded-md h-10"}
                                    key={`w_${index}`}
                                    type="number"
                                    value={weight}
                                    disabled={readOnly}
                                    onChange={(e) => {
                                        const newWeights = [...exercise.actualWeights];
                                        newWeights[index] = e.target.value === "" || e.target.value === null ? "" : parseFloat(e.target.value);
                                        setExercise({
                                            ...exercise,
                                            actualWeights: newWeights
                                        });
                                    }}
                                />}
                                {!exercise.hasWeights && <span className={"mr-5 text-accent"}>Bodyweight</span>}
                                {exercise.hasWeights && <span className={"ml-2 mr-5 text-accent"}>kg</span>}
                                <input className={"w-20 text-center rounded-md h-10 "}
                                       key={`r_${index}`}
                                       type="number"
                                       value={exercise.actualReps[index]}
                                       disabled={readOnly}
                                       onChange={(e) => {
                                           const newReps = [...exercise.actualReps];
                                           const reps = e.target.value === "" || e.target.value === null ? "" : parseInt(e.target.value);
                                           newReps[index] = reps;
                                           if (reps !== "") {
                                               calculateNewWeights(index, reps, newReps);
                                           } else {
                                               setExercise({
                                                   ...exercise,
                                                   actualReps: newReps
                                               })
                                           }
                                       }}
                                />
                                <span className={"ml-2 text-accent"}>reps</span>

                                {/* superset part */}
                                {exercise.superSetOtherExerciseName && <span className={"ml-5 text-accent"}>+ 3 reps {exercise.superSetOtherExerciseName}</span>}
                            </div>
                        ))}

                        {/* Difficulty Slider */}
                        <div className={"my-5"}>
                            <label>Schwierigkeit:</label>
                            <input
                                className={"mx-3"}
                                type="range"
                                min="1"
                                max="5"
                                value={exercise.difficulty}
                                disabled={readOnly}
                                onChange={handleSliderChange}
                            />
                            <span>{getDifficultyLabel(exercise.difficulty)}</span>
                        </div>

                        {/* Add more exercise details as needed */}
                        <p className={"text-red-500"}>{errors}</p>
                        {!readOnly && <button className={"bg-accent border rounded-md p-5 w-full"} onClick={handleFinishExercise}>Übung abschließen
                        </button>}
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </div>
    );
};

export default WorkoutexercisePage;
